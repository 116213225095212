import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox.js';
import { setAuthInfo, setLoading} from './actions/main.js'
import { setSearchKeySelect, setSearchPulldownValue, setSearchOptions } from './actions/searchbox'
import { setOpenCompanyProfile, setCustomStyles } from './actions/ui.js'
import { getTokenCookie, setCookie,assetUrl } from './utils/Common.js'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import { useParams } from 'react-router-dom'
import { ESHOW_URL } from './config.js'
import { makeStyles } from '@mui/styles';
import ReactReadMoreReadLess from "react-read-more-read-less";
import SearchBox from './viewer/component/SearchBox.js';
import Footer from './viewer/component/Footer.js';

import IconButton from '@mui/material/IconButton';

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
    const [filteredItems, setFilteredItems] = useState([])
  const [openReleaseDetails, setOpenReleaseDetails] = React.useState(false);
  const page_size = 24;  
  
  let standardSearchOptions = props.searchbox.searchOptions?.STANDARD??[]
  let keySelect = props.searchbox?.searchKeySelect
  let pulldownValue = props.searchbox?.searchPulldownValue
  let searchInputValue = props.searchbox?.searchInputValue
  var selectedAdvancedFilterOptions = props.searchbox.selectedAdvancedFilterOptions
  
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();
  const handleChangePage = (event, value) => {
    setPage(value);
  };


  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [{EXHIBITOR_KEY: exhibitor.EXHIBITOR_KEY}]
    booth.booth_number = exhibitor?.BOOTH_NO

    props.setOpenCompanyProfile({booth:booth, open:true})
  }

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const fetchData = ()=>{    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getPressReleases` 
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        props.setSearchOptions(result.SEARCH) 
        // if(result.SUCCESS){  
          if(result.PRESS){
            setData(result.PRESS)            
          }
        // }
        // else{
        //   setData([])
        // }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY='scroll'
    props.setOpenCompanyProfile(false)
    fetchData()
  }, [id5, id4, id3, id2, id1])

  useEffect(() => {
    if(data.length)
      doSearch()
  }, [data])

  
  useEffect(() => {
    if(data.length)
      debouncedSearch()
  }, [keySelect, pulldownValue, searchInputValue, selectedAdvancedFilterOptions])  
    
  const doSearch=(value)=>{
    var keyword = null
    let searchOption = getSearchOption(keySelect)
    if(value){
      keyword = value
    }
    else{
      if(searchOption && (searchOption.TYPE == 'pulldown' || searchOption.TYPE == 'checkbox')){
        keyword = pulldownValue
      }
      else{
        keyword = searchInputValue
      }
    }
    if(!keyword) keyword = ''
    keyword = `${keyword}`
    var filteredItems = data;
    if(keyword == "" || keyword == '""' || !keyword){
      setFilteredItems(filteredItems)
      return;
    }
    if(keyword && keyword.trim() !== '') {
      filteredItems = data.filter((item)=>{
        var str = ''
        if(keySelect == "description"){
          str = `${item.DESCRIPTION?item.DESCRIPTION:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "company"){
          str = `${item.COMPANY_NAME?item.COMPANY_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Product Categories"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }          
        else if(keySelect == "booth"){
          str = `${item.BOOTH_NO?item.BOOTH_NO:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "map_name"){
          str = `${item.MAP_NAME?item.MAP_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "state"){
          str = `${item.STATE?item.STATE:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "country"){
          str = `${item.COUNTRY?item.COUNTRY:'us'}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == 'Featured Exhibitors'){
          str = `${item.FEATURED?item.FEATURED:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Region of Services"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }     
        else if(keySelect == "Service Specialty"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }             
        else{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }
      })      
    }
    setFilteredItems(filteredItems)
  }

  const debouncedSearch = debounce(doSearch, 300);
  
  const getSearchOption = (value)=>{
    if (!value) return false;
    for(var i = 0; i < standardSearchOptions?.length; i++){
      if(standardSearchOptions[i].ID == value){
        return standardSearchOptions[i]
      }
    }
    return false
  }

  var pageData = paginate(filteredItems, page_size, page);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false 
  }

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1200, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        <Box textAlign={'center'}>
          <SearchBox/>
        </Box>
        <Box mt={2}>
            {
              pageData.map((release, index)=>{
                return(
                  <Box key={index} display={'flex'} mb={2} style={{boxSizing:'border-box', textAlign:'left'}}>
                    <Box style={{width:'80px', boxSizing:'border-box'}}>
                      {release?.['HEADER_IMAGE'] &&
                        <img style={{width:'80px', height:'80px', objectFit:'contain', borderRadius:'5px', overflow:'hidden'}} width="80px" height="80px" src={release?.['HEADER_IMAGE']}/>
                      }
                    </Box>
                    <Box display={'flex'} alignItems={'center'} style={{width:'calc(100% - 80px)', padding: '3px 12px', boxSizing:'border-box'}}>
                      <div>
                        <h4 style={{marginTop:0, marginBottom:6}}>
                          <a className='sub-exhibtor-title' href="javascript:void(0)"
                            onClick={()=>{
                              setOpenReleaseDetails(release)
                            }}  
                          >
                            {release?.['TITLE']}
                          </a>
                        </h4>
                        {release?.['SUB_TITLE'] &&
                          <h5 style={{marginTop:0, marginBottom:6}}>{release?.['SUB_TITLE']} </h5>
                        }
                        <div
                          style={{marginTop:0, marginBottom:6}}
                        >
                          {/* <ShowMore
                            lines={5}
                            more='Read More'
                            less='Read Less'
                            anchorClass=''
                          >
                            {release?.['DESCRIPTION']} 
                          </ShowMore> */}
                          <ReactReadMoreReadLess
                              charLimit={250}
                              readMoreText={"Read more"}
                              readLessText={"Read less"}
                          >
                            {release?.['DESCRIPTION']} 
                          </ReactReadMoreReadLess>
                          {/* {release?.['DESCRIPTION']} */}
                        </div>                        
                        {release?.['COMPANY_NAME'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>
                            <a href="javascript:void(0)" onClick={()=>{openCompanyProfile(release)}}>
                                <b >{release.COMPANY_NAME}</b><br/>
                            </a>
                          </p>
                        }                 
                        {release?.['BOOTH_NO'] &&
                          <p className='sub-title' style={{marginTop:0, marginBottom:6}}>Booth: {release?.['BOOTH_NO']} </p>
                        }
                        <p className='sub-title' style={{marginTop:0, marginBottom:6}}>{release?.['CITY']}, {release?.['STATE']} {release?.['COUNTRY']}  {release?.['PHONE']}</p>
                        {release.DOCUMENT &&  download_show &&
                        <Box mt={1}>
                          <a className='' style={{marginRight:'10px', color:'#0069aa'}} target='_blank' download={true} href={release.DOCUMENT}>                              
                            Download
                          </a>
                        </Box>
                        }
                      </div>
                    </Box>                     
                  </Box>
                )
              })
            }
            {
              (!data || data.length == 0) && dataFetched && 
              <Box style={{textAlign:'center', width:'100%'}}>
                <label>No Show Case Found.</label>
              </Box>
            }
        </Box>
      </Box>
      <Box mt={3} mb={8}>
        <Stack spacing={2}>
          <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(data.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
        </Stack>
      </Box>
      <Footer/>
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
        {
          openReleaseDetails &&         
          <Box className='exhibitor-details-popup press-releases'>
            {openReleaseDetails?.['HEADER_IMAGE'] &&
            <img  src={openReleaseDetails.HEADER_IMAGE}/>
            }
            <div>
              <IconButton className='exhibitor-details-close-btn' size="small" aria-label="close" color="inherit" onClick={()=>setOpenReleaseDetails(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <Box className='exhibitor-details-description' textAlign={'left'} p={2}>
              <h3 style={{marginTop:0}}>{openReleaseDetails.TITLE}</h3>              
              {openReleaseDetails?.['SUB_TITLE'] &&
                <h5 style={{marginTop:0, marginBottom:6}}>{openReleaseDetails?.['SUB_TITLE']} </h5>
              }
              <div className='sub-title' style={{marginTop:0, marginBottom:3}}>{openReleaseDetails?.['CITY']}, {openReleaseDetails?.['STATE']} {openReleaseDetails?.['COUNTRY']}  {openReleaseDetails?.['PHONE']}</div>
             
              {openReleaseDetails?.['COMPANY_NAME'] &&
                <p className='sub-title' style={{marginTop:0, marginBottom:3}}>{openReleaseDetails?.['COMPANY_NAME']} </p>
              }                 
              {openReleaseDetails?.['BOOTH_NO'] &&
                <p className='sub-title' style={{marginTop:0, marginBottom:3}}>Booth: {openReleaseDetails?.['BOOTH_NO']} </p>
              }
              {openReleaseDetails.DOCUMENT &&  download_show &&
              <Box mt={2} display={'flex'}>
                <a className='round-icon-btn' style={{marginRight:'10px'}} target='_blank' download href={openReleaseDetails.URL}>
                  <DownloadIcon style={{color:'white'}}/>
                </a>
              </Box>
              }
              <Box mt={2}>
                {openReleaseDetails.DESCRIPTION} 
              </Box>                    
            </Box>
          </Box>
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
    searchbox: state.searchbox,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setSearchKeySelect:  data=>dispatch(setSearchKeySelect(data)),
      setSearchPulldownValue:  data=>dispatch(setSearchPulldownValue(data)),
      setSearchOptions:  data=>dispatch(setSearchOptions(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
